<template>
    <div>
        <div class="relative bg-white overflow-hidden">
            <div class="max-w-7xl mx-auto">
              <div
                class="
                  relative
                  z-10
                  pb-8
                  bg-white
                  sm:pb-16
                  md:pb-20
                  lg:max-w-2xl
                  lg:w-full
                  lg:pb-28
                  xl:pb-32
                "
              >
                <svg
                  class="
                    hidden
                    lg:block
                    absolute
                    right-0
                    inset-y-0
                    h-full
                    w-48
                    text-white
                    transform
                    translate-x-1/2
                  "
                  fill="currentColor"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <div>
                  <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
                    <nav
                      class="
                        relative
                        flex
                        items-center
                        justify-between
                        sm:h-10
                        lg:justify-start
                      "
                      aria-label="Global"
                    >
                      <div
                        class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0"
                      >
                        <div class="flex items-center justify-between w-full md:w-auto">
                          <router-link to="/">
                            <span class="sr-only">Mystidia</span>
                            <img
                              class="h-8 w-auto sm:h-10"
                              src="../../assets/mystidia.png"
                            />
                          </router-link>
                          <div class="-mr-2 flex items-center md:hidden">
                            <button
                              @click="show = true"
                              type="button"
                              class="
                                bg-white
                                rounded-md
                                p-2
                                inline-flex
                                items-center
                                justify-center
                                text-gray-400
                                hover:text-gray-500
                                hover:bg-gray-100
                                focus:outline-none
                                focus:ring-2 focus:ring-inset focus:ring-indigo-500
                              "
                              aria-expanded="false"
                            >
                              <span class="sr-only">Open main menu</span>
                              <!-- Heroicon name: outline/menu -->
                              <svg
                                class="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M4 6h16M4 12h16M4 18h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                        <router-link
                          to="/"
                          class="font-medium text-gray-500 hover:text-gray-900"
                          >Accueil</router-link
                        >
                        <button
                          @click="scroll('about')"
                          class="font-medium text-gray-500 hover:text-gray-900"
                          >À propos</button
                        >
                        <button
                          @click="scroll('tarifs')"
                          class="font-medium text-gray-500 hover:text-gray-900"
                          >Tarifs</button
                        >
                        <!-- <router-link
                          to=""
                          class="font-medium text-gray-500 hover:text-gray-900"
                          >Contact</router-link
                        > -->
                        <router-link
                          to="/login"
                          class="font-medium text-red-500 hover:text-red-400"
                          >Se connecter</router-link
                        >
                      </div>
                    </nav>
                  </div>

                  <div
                    class="
                      absolute
                      top-0
                      inset-x-0
                      p-2
                      transition
                      transform
                      origin-top-right
                      md:hidden
                    "
                  >
                    <div
                      v-if="show"
                      class="
                        rounded-lg
                        shadow-md
                        bg-white
                        ring-1 ring-black ring-opacity-5
                        overflow-hidden
                      "
                    >
                      <div class="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img
                            class="h-8 w-auto"
                            src="../../assets/mystidia.png"
                            alt=""
                          />
                        </div>
                        <div class="-mr-2">
                          <button
                            @click="show = false"
                            type="button"
                            class="
                              bg-white
                              rounded-md
                              p-2
                              inline-flex
                              items-center
                              justify-center
                              text-gray-400
                              hover:text-gray-500
                              hover:bg-gray-100
                              focus:outline-none
                              focus:ring-2 focus:ring-inset focus:ring-red-400
                            "
                          >
                            <span class="sr-only">Close main menu</span>
                            <!-- Heroicon name: outline/x -->
                            <svg
                              class="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div class="px-2 pt-2 pb-3 space-y-1">
                        <router-link
                          to="/"
                          class="
                            block
                            px-3
                            py-2
                            rounded-md
                            text-base
                            font-medium
                            text-gray-700
                            hover:text-gray-900
                            hover:bg-gray-50
                          "
                          >Accueil</router-link
                        >
                        <router-link
                          to="/"
                          class="
                            block
                            px-3
                            py-2
                            rounded-md
                            text-base
                            font-medium
                            text-gray-700
                            hover:text-gray-900
                            hover:bg-gray-50
                          "
                          >À propos</router-link
                        >
                        <router-link
                          to="/"
                          class="
                            block
                            px-3
                            py-2
                            rounded-md
                            text-base
                            font-medium
                            text-gray-700
                            hover:text-gray-900
                            hover:bg-gray-50
                          "
                          >Tarifs</router-link
                        >
                        <!-- <router-link
                          to="/"
                          class="
                            block
                            px-3
                            py-2
                            rounded-md
                            text-base
                            font-medium
                            text-gray-700
                            hover:text-gray-900
                            hover:bg-gray-50
                          "
                          >Contact</router-link
                        > -->
                      </div>
                      <router-link
                        to="/login"
                        class="
                          block
                          w-full
                          px-5
                          py-3
                          text-center
                          font-medium
                          text-red-400
                          bg-gray-50
                          hover:bg-gray-100
                        "
                      >
                        Se connecter
                      </router-link>
                    </div>
                  </div>
                </div>

                <main
                  class="
                    mt-10
                    mx-auto
                    max-w-7xl
                    px-4
                    sm:mt-12
                    sm:px-6
                    md:mt-16
                    lg:mt-20
                    lg:px-8
                    xl:mt-28
                  "
                >
                  <div class="sm:text-center lg:text-left">
                    <h1
                      class="
                        text-4xl
                        tracking-tight
                        font-extrabold
                        text-gray-900
                        sm:text-5xl
                        md:text-6xl
                      "
                    >
                      <span class="block xl:inline"
                        >Augmentez vos notes avec plus de
                      </span>
                      <span class="block text-red-400 xl:inline">QCMs</span>
                    </h1>
                    <p
                      class="
                        mt-3
                        text-base text-gray-500
                        sm:mt-5
                        sm:text-lg
                        sm:max-w-xl
                        sm:mx-auto
                        md:mt-5
                        md:text-xl
                        lg:mx-0
                      "
                    >
                      <span class="block text-red-400 xl:inline">Mystidia Exams</span>
                      La plateforme contenant le plus de QCMs pour tous les étudiants de
                      médecine
                    </p>
                    <div
                      class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
                    >
                      <div class="rounded-md shadow">
                        <router-link
                          to="/register"
                          class="
                            w-full
                            flex
                            items-center
                            justify-center
                            px-14
                            py-3
                            border border-transparent
                            text-base
                            font-medium
                            rounded-md
                            text-white
                            bg-red-400
                            hover:bg-red-500
                            md:py-4
                            md:text-lg
                            md:px-14
                          "
                        >
                          Commencer
                        </router-link>
                      </div>
                      <div class="mt-3 sm:mt-0 sm:ml-3">
                        <router-link
                          to="/login"
                          class="
                            w-full
                            flex
                            items-center
                            justify-center
                            px-11
                            py-3
                            border border-transparent
                            text-base
                            font-medium
                            rounded-md
                            text-red-500
                            bg-red-100
                            hover:bg-red-200
                            md:py-4
                            md:text-lg
                            md:px-11
                          "
                        >
                          Se connecter
                        </router-link>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
            <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                src="../../assets/test.png"
                alt=""
              />
            </div>
        </div>
        <Information id="about" />
        <Tarifs id="tarifs" />
    </div>
</template>

<script>
import Tarifs from './Tarifs.vue'
import Information from './Information.vue'

export default {
    name: 'Accueil',
    components: {
        Tarifs,
        Information
    },
    data () {
      return {
        show : false
      }
    },

    methods: {
        scroll(id) {  
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        }
    }
}
</script>

<style>

</style>