<template ref="tarifs">
  <div class="pricing-table-2 bg-gray-50 py-6 md:py-12">
  <div class="container mx-auto px-4">

    <div class="max-w-3xl mx-auto text-center">
      <h1 class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
          Nos tarifs
      </h1>
      <p class="mt-3 text-base text-gray-500 xl:mx-12 sm:mt-5 sm:text-lg    md:text-xl">
          Nous vous proposons trois types d'abonnements selon vos besoins avec des tarifs abordables et étudiés.
      </p>
     
    </div>

    <div class="pricing-plans lg:flex lg:mx-8 mt-6 md:mt-12">

      <div class="pricing-plan-wrap lg:w-1/3 my-4 md:my-6">
        <div class="pricing-plan border-t-4 border-solid border-white xl:rounded-lg lg:rounded-tl-lg lg:rounded-bl-lg lg:rounded-tr-none lg:rounded-br-none rounded-lg shadow-lg bg-white text-center max-w-xs mx-auto hover:border-red-400 hover:shadow-2xl transition-colors duration-300">
          <div class="p-6 md:py-8">
            <h4 class="font-medium leading-tight text-2xl mb-2">Modulaire</h4>
            <p class="text-gray-600">Module de l'année en cours</p>
          </div>
          <div class="pricing-amount bg-red-100 p-6 transition-colors duration-300">
            <!-- <div class=""><span class="text-4xl font-semibold">2000 DA</span> /Année</div> -->
            <div class="text-2xl">Tarifs prochainement</div>
          </div>
          <div class="p-6">
            <ul class="leading-loose">
              <li>Révision et Examen</li>
              <li>Historique des réponses</li>
              <li>Mises à jours gratuites</li>
              <li>Disponibilité sur PC et mobile</li>
              <li>Possibilité d'achat par module</li>
              <!-- <li>Playlists</li> -->
            </ul>
            <div class="mt-6 py-4">
              <button class="bg-red-400 text-base font-medium px-11 py-3 text-white rounded-md hover:bg-red-500 transition-colors duration-300">Commencer</button>
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-plan-wrap lg:w-1/3 my-4 md:my-6">
        <div class="pricing-plan border-t-4 border-solid border-white bg-white xl:rounded-lg lg:rounded-tl-none lg:rounded-bl-none lg:rounded-tr-none lg:rounded-br-none rounded-lg shadow-lg text-center max-w-xs mx-auto hover:border-red-400 hover:shadow-2xl transition-colors duration-300">
          <div class="p-6 md:py-8">
            <h4 class="font-medium leading-tight text-2xl mb-2">Annuelle</h4>
            <p class="text-gray-600">Tous les modules de l'année</p>
          </div>
          <div class="pricing-amount bg-red-100 p-6 transition-colors duration-300">
            <!-- <div><span class="text-4xl font-semibold">4000 DA</span> /Année</div> -->
            <div class="text-2xl">Tarifs prochainement</div>
          </div>
          <div class="p-6">
            <ul class="leading-loose">
              <li>Révision et Examen</li>
              <li>Historique des réponses</li>
              <li>Mises à jours gratuites</li>
              <li>Disponibilité sur PC et mobile</li>
              <li>Accès aux modules de l'année en cours</li>
              <!-- <li>Playlists</li> -->
            </ul>
            <div class="mt-6 py-4">
              <button class="bg-red-400 text-base font-medium px-11 py-3 text-white rounded-md hover:bg-red-500 transition-colors duration-300">Commencer</button>
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-plan-wrap lg:w-1/3 my-4 md:my-6">
        <div class="pricing-plan border-t-4 border-solid border-white  bg-white xl:rounded-lg lg:rounded-tl-none lg:rounded-bl-none lg:rounded-tr-lg lg:rounded-br-lg rounded-lg shadow-lg text-center max-w-xs mx-auto hover:border-red-400 hover:shadow-2xl transition-colors duration-300">
          <div class="p-6 md:py-8">
            <h4 class="font-medium leading-tight text-2xl mb-2">Résidanat</h4>
            <p class="text-gray-600">Tous les modules</p>
          </div>
          <div class="pricing-amount bg-red-100 p-6 transition-colors duration-300">
            <!-- <div class=""><span class="text-4xl font-semibold">3000 DA</span> /Année</div> -->
            <div class="text-2xl">Tarifs prochainement</div>
          </div>
          <div class="p-6">
            <ul class="leading-loose">
              <li>Révision et Examen</li>
              <li>Historique des réponses</li>
              <li>Mises à jours gratuites</li>
              <li>Disponibilité sur PC et mobile</li>
              <li>Accès à tous les modules</li>
              <!-- <li>Playlists</li> -->
            </ul>
            <div class="mt-6 py-4">
              <button class="bg-red-400 text-base font-medium px-11 py-3 text-white rounded hover:bg-red-500 transition-colors duration-300">Commencer</button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>