<template>
  <div class="py-12 bg-gray-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p
          class="
            mt-2
            text-3xl
            leading-8
            font-extrabold
            tracking-tight
            text-gray-900
            sm:text-4xl
          "
        >
          La meilleure façon d'étudier
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Notre solution permet un meilleur apprentissage rapide et efficace
          avec les meilleures techniques et les meilleurs QCMs
        </p>
      </div>

      <div class="mt-10 mx-10">
        <dl
          class="
            space-y-10
            md:space-y-0
            md:grid md:grid-cols-2
            md:gap-x-8 md:gap-y-10
          "
        >
          <div class="relative">
            <dt>
              <div
                class="
                  absolute
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-md
                  bg-red-400
                  text-white
                "
              >
                <!-- Heroicon name: outline/globe-alt -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                Étudiez Partout
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Notre solution est conçue pour être utilisée sur tous les
              appareils pour vous offrir un maximum de mobilité.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div
                class="
                  absolute
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-md
                  bg-red-400
                  text-white
                "
              >
                <!-- Heroicon name: outline/scale -->
                <img
                  src="https://img.icons8.com/material-outlined/24/ffffff/increase-font.png"
                />
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                Augmentez vos notes
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Assurez un avenir brillant avec un service qui vous aide à obtenir
              la meilleure note possible à n'importe quel test.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div
                class="
                  absolute
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-md
                  bg-red-400
                  text-white
                "
              >
                <!-- Heroicon name: outline/lightning-bolt -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                Apprenez plus rapidement
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Notre plateforme vous permet à comprendre et à mémoriser
              l'information plus rapidement.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div
                class="
                  absolute
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-md
                  bg-red-400
                  text-white
                "
              >
                <!-- Heroicon name: outline/annotation -->
                <img src="https://img.icons8.com/windows/32/ffffff/time.png" />
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                Maximisez votre temps libre
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              Nos outils d'étude rendent l'apprentissage plus efficace, vous
              permettent de profiter de plus d'activités et de loisirs.
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>